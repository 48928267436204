// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISH_KEY);

import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isLoading   : false,
      loading     : false,
      sessionId   : [],
      setupIntent : {},

      // plans       : [],
    }
  },
  methods : {
    ...mapActions({
      'getSubscription' : 'subscription/getSubscription',
    }),

    /**
     * Steps to Create Stripe Subscription:
     *
     * 1. Create Stripe Checkout Session and Redirect to Stripe's Checkout page
     *    <see checkoutSession>
     * 2. (After successful payment)
     *    - If subscription created via Pricing page -> Redirect to Main page
     *    - If subscription created via Payment Methods page ->
     *        Redirect to Subscription page
     * 3. Get Stripe Checkout Session data based on the route parameters
     *      (action and sessionId) <see getCheckoutSession>
     * 4. Get Stripe Subscription data <see getStripeSubscription>
     *    - Subscription data is saved in the database via backend
     */

    /**
     * Steps to Update Stripe's Payment Method only:
     *
     * 1. Create Stripe Checkout Session and Redirect to Stripe's Checkout page
     *    <see checkoutSession>
     * 3. (After successful update) Redirect to Subscription page
     * 4. Get Stripe Checkout Session data based on the route parameters
     *      (action and sessionId) <see getCheckoutSession>
     * 4. Update Stripe Payment Method <see updateStripePaymentMethod>
     */

    /**
     * Get Subscription Plans
     */
    // getPlans() {
    //   this.$http.get('api/subscription/plans')
    //     .then(response => {
    //       this.plans = response.data;
    //     });
    // },

    /**
     * Create Stripe Checkout Session
     * @param transactionType
     * @param data
     */
    checkoutSession(transactionType, data) {
      this.loading = true;
      this.isLoading = true;
      let params = {};

      if (transactionType === 'create-subscription') {
        params = {
          transaction   : transactionType,
          planId        : data.planId,
          stripePriceId : data.stripePriceId,
          source        : data.source,
          freeTrial     : data.freeTrial,
        };
      }

      if (transactionType === 'update-payment' ||
        transactionType === 'update-subs-payment') {
        params = {
          transaction          : transactionType,
          stripeCustomerId     : data.stripeCustomerId,
          stripeSubscriptionId : data.stripeSubscriptionId,
          source               : data.source,
        };

        if (transactionType === 'update-subs-payment'){
          params.newPlanId = data.newPlanId;
          params.newStripePriceId = data.newStripePriceId;
        }
      }

      this.$http.post('api/stripe/checkout', params)
        .then(response => {
          this.sessionId = response.data.data.id;
          if (this.sessionId) {

            // Redirect to Stripe Checkout page
            stripe.redirectToCheckout({
              sessionId : this.sessionId,
            }).then(() => {
              console.log("Redirect Checkout");
            }).catch(() => {
              this.$notify({
                group : 'notif',
                type  : 'failed',
                title : 'Failed',
                text  : 'Oops! Something went wrong!',
              });
            }).finally(() => {
              this.loading = false;
              this.isLoading = false;
            });
          }
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.isLoading = false;
          this.loading = false;
        });
    },


    /**
     * Get Stripe's Checkout Session
     */
    getCheckoutSession() {
      this.isLoading = true;
      this.loading = true;

      this.$http.get('api/stripe/checkout', {
        params : {
          sessionId : this.$route.params.sessionId,
        },
      }).then(response => {
        this.session = response.data.data;

        // Update Stripe Payment Method
        if (this.$route.params.action === 'update-payment-success')
          this.updateStripePaymentMethod(this.session);
        else {
          // Check if stripe subscription exists
          this.checkActiveSubscription('stripe subscription id',
            this.session.subscription);
        }
      }).catch(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
     * Check Active Subscription
     * @param key
     * @param value
     */
    checkActiveSubscription(key, value) {
      this.$http.get('api/subscription/check', {
        params : {
          key   : key,
          value : value,
        },
      }).then(async response => {
        if (response.data) {
          // Subscription already exists
          this.$notify({
            group : 'notif',
            type  : 'warn',
            title : 'Subscription',
            text  : "Subscription already exists",
          });
          this.loading = false;
          this.$router.push('/subscription-process/saved');
        } else {
          // Subscription does not exist
          if (key === 'stripe subscription id') {
            // Get Stripe Subscription data
            if (this.$route.params.action === 'create-success')
              this.getStripeSubscription(this.session.subscription);
  
            // // Update Stripe Payment Method
            // if (this.$route.params.action === 'update-payment-success')
            //   this.updateStripePaymentMethod(this.session);
          } else if (key === 'paypal subscription id')
            this.getPaypalSubscription('create-subscription');
        }
      }).catch(() => {
        this.loading = false;
        this.$notify({
          group : 'notif',
          type  : 'error',
          title : 'Failed',
          text  : 'Oops! Something went wrong!',
        });
      });
    },

    /**
     * Get Stripe Subscription Data
     * @param stripeSubscriptionId
     */
    async getStripeSubscription(stripeSubscriptionId) {
      await this.$http.get('api/stripe/subscription', {
        params : {
          stripeSubscriptionId : stripeSubscriptionId,
          action               : this.$route.params.action,
          planId               : this.session.metadata.plan_id,
          userId               : this.$store.getters['user/user'].user_id,
        },
      }).then(async response => {
        const stripeSubs = response.data.data;

        if (!_.isEmpty(stripeSubs)) {
          // Check if there is an existing subscription
          if (this.$store.getters['subscription/subscription'].platform ===
            'paypal')
            this.cancelPaypalSubscription('cancel-subscription');
          else {
            await this.$store.dispatch('subscription/getSubscription');
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Subscription',
              text  : "Success! Welcome and enjoy Healthy Hip Hop's " +
                "awesome features.",
            });

            // Redirect to Subscription's landing page
            this.$router.push('/subscription-process/saved');
            
            // // Redirect back to remove the route params
            // if (localStorage.getItem('regPath') === '/pricing' ||
            // localStorage.getItem('socialRegPath') === '/pricing') {
            //   // this.$router.push('/browse/main');
            //   this.$router.push('/dashboard');
            //   localStorage.removeItem('regPath');
            //   localStorage.removeItem('socialRegPath');
            //   localStorage.removeItem('selectedSubPlan');
            // } else
            //   ('/subscription');
          }
        }
      }).catch(() => {
        this.$notify({
          group : 'notif',
          type  : 'error',
          title : 'Failed',
          text  : 'Oops! Something went wrong!',
        });
      }).finally(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
     * Update Stripe Payment Method
     * @param session
     */
    updateStripePaymentMethod(session) {
      const subscription = this.$store.getters['subscription/subscription'];
      this.$http.put('api/stripe/paymentMethod', {
        subscriptionId      : subscription.id,
        stripeSetupIntentId : session.setup_intent,
        metadata            : (_.isEmpty(session.metadata)) ? {} :
          session.metadata,
      }).then(() => {
        if (session.metadata.new_plan_id ||
          session.metadata.subscription_plan_id) {
          this.updateStripeSub({
            newStripePriceId : session.metadata.new_stripe_price_id,
            newPlanId        : session.metadata.new_plan_id,
          });
        } else {
          this.isLoading = false;
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Subscription payment method updated successfuly',
          });
          this.$router.push('/payment-details');
        }
      }).catch(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
     * Update Stripe Subscription
     * @param data
     */
    updateStripeSub(data) {
      const subscription = this.$store.getters['subscription/subscription'];
      this.$http.put('api/stripe/subscription', {
        subscriptionId       : subscription.id,
        stripeSubscriptionId : subscription.stripe_subscription_id,
        stripePriceId        : data.newStripePriceId,
        planId               : data.newPlanId,
      }).then(() => {
        this.$notify({
          group : 'notif',
          type  : 'success',
          title : 'Success!',
          text  : 'Subscription updated successfully!',
        });
        this.$store.dispatch('subscription/getSubscription');
        this.$modal.hide('dialog');
        this.$router.push('/subscription');
      }).catch(() => {
        this.$notify({
          group : 'notif',
          type  : 'failed',
          title : 'Failed',
          text  : 'Oops! Something went wrong!',
        });
      }).finally(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
     * Cancel Stripe Subscription
     * @param action
     */
    cancelStripeSubscription(action) {
      this.isLoading = true;
      this.loading = true;
      const subscription = this.$store.getters['subscription/subscription'];
      const isTrial = (subscription.trial === 1) ? true : false;
      this.$http.delete('api/stripe/subscription', {
        data : {
          subscriptionId       : subscription.id,
          stripeSubscriptionId : subscription.stripe_subscription_id,
          planClassName        : subscription.class_name,
          action               : action,
        },
      }).then(() => {
        this.$store.dispatch('subscription/getSubscription');
        let notif = null;

        if (action === 'expiring-subscription') {
          notif = 'Canceled subscription successfully';
          this.$emit('close');
        } else if (action === 'cancel-subscription') {
          if (isTrial){
            notif = 'Canceled subscription successfully';
            this.$emit('close');
          } else {
            notif = 'Updated subscription successfully';
            localStorage.removeItem('selectedSubPlan');
            localStorage.removeItem('paypalSubscription');
            this.$router.push('/subscription-process/saved');
          }
        }

        this.$notify({
          group : 'notif',
          type  : 'success',
          title : 'Success!',
          text  : notif,
        });
      }).catch(() => {
        this.$notify({
          group : 'notif',
          type  : 'error',
          title : 'Failed',
          text  : 'Oops! Something went wrong!',
        });
      }).finally(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
     * Get PayPal Subscription
     * @param action
     */
    getPaypalSubscription(action) {
      this.isLoading = true;
      this.loading = true;
      const paypalSubscription = JSON.parse(
        localStorage.getItem('paypalSubscription'));
      const selectedPlan = JSON.parse(localStorage.getItem('selectedSubPlan'));

      this.$http.get('api/paypal/subscription', {
        params : {
          paypalSubscriptionId : paypalSubscription.subscriptionID,
          planId               : selectedPlan.id,
          action               : action,
        },
      }).then(async response => {
        if (response.data.data.status === 'ACTIVE') {
          if (this.$store.getters['subscription/subscription'].platform ===
            'stripe')
            this.cancelStripeSubscription('cancel-subscription');
          else {
            this.$store.dispatch('subscription/getSubscription');
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Subscription',
              text  : "Success! Welcome and enjoy Healthy Hip Hop's " +
                "awesome features.",
            });
            localStorage.removeItem('paypalSubscription');
            localStorage.removeItem('selectedSubPlan');

            // Redirect to Subscription's landing page
            this.$router.push('/subscription-process/saved');
          }
        }
      }).catch(() => {
        this.$notify({
          group : 'notif',
          type  : 'error',
          title : 'Failed',
          text  : 'Oops! Something went wrong!',
        });
      }).finally(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
     * Cancel PayPal Subscription
     * @param action
     */
    cancelPaypalSubscription(action) {
      this.isLoading = true;
      this.loading = true;
      const subscription = this.$store.getters['subscription/subscription'];
      const isTrial = (subscription.trial === 1) ? true : false;
      this.$http.delete('api/paypal/subscription', {
        data : {
          subscriptionId       : subscription.id,
          paypalSubscriptionId : subscription.paypal_subscription_id,
          action               : action,
        },
      }).then(() => {
        this.$store.dispatch('subscription/getSubscription');
        let notif = null;
        if (action === 'expiring-subscription') {
          notif = 'Canceled subscription successfully';
          this.$emit('close');
        } else if (action === 'cancel-subscription') {
          if (isTrial) {
            notif = 'Canceled subscription successfully';
            this.$emit('close');
          } else {
            notif = 'Updated subscription successfully';
            localStorage.removeItem('selectedPlan');
            this.$router.push('/subscription-process/saved');
          }
        }
        this.$notify({
          group : 'notif',
          type  : 'success',
          title : 'Success!',
          text  : notif,
        });
      }).catch(() => {
        this.$notify({
          group : 'notif',
          type  : 'error',
          title : 'Failed',
          text  : 'Oops! Something went wrong!',
        });
      }).finally(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },

    /**
      * Update Subscription data in the database
      * @param fields
      * @param subscriptionId
      */
    updateSubscription(fields, subscriptionId) {
      this.isLoading = true;
      this.loading = true;
      this.$http.put('api/subscription', {
        subscriptionId : subscriptionId,
        fields         : fields,
      }).then(() => {
        // Fetch updated subscription details
        this.$store.dispatch('subscription/getSubscription');

        // Update successful notification
        this.$notify({
          group : 'notif',
          type  : 'success',
          title : 'Success!',
          text  : (fields.plan_id) ?
            'Subscription updated successfully!' :
            'Subscription Payment updated successfully!',
        });

        // Change url to remove route params
        this.$router.push('/subscription');
      }).finally(() => {
        this.isLoading = false;
        this.loading = false;
      });
    },
  },
};